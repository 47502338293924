import * as requestFromServer from "./adminUsersCrud";
import { callTypesUsers, adminUsersSlice } from "./adminUsersSlice";

const { actions: adminUsersActions } = adminUsersSlice;

export const fetchAdminUsers = (
  queryParams,
  search_keyword = "",
  search_status = "",
  search_role = "",
  referer_id = 0
) => async (dispatch) => {
  dispatch(adminUsersActions.startCall({ callType: callTypesUsers.list }));
  try {
    const response = await requestFromServer.getUsersList(
      queryParams,
      search_keyword,
      search_status,
      search_role,
      referer_id
    );
    const {
      data: { users, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        adminUsersActions.storeSearch({
          hits_total: total,
          sources: users
        })
      );
    }, 500);
  } catch (e) {
    dispatch(adminUsersActions.catchError({ callType: callTypesUsers.list }));
  }
};

export const addAdminUsers = (data, assignUserDetails) => async (dispatch) => {
  dispatch(adminUsersActions.startCall({ callType: callTypesUsers.list }));
  try {
    const response = await requestFromServer.addUserList(data);
    const {
      status,
      data: { id }
    } = response.data;

    if (status) {
      const assignUser = Object.assign(assignUserDetails, {
        user_id: id.toString()
      });
      const userResponse = await requestFromServer.addUsersCompanyList(
        assignUser
      );
      const {
        data: { id: assignId }
      } = userResponse.data;

      if (assignId) {
        const response = await requestFromServer.getUsersList();
        const {
          data: { users, total }
        } = response.data;

        dispatch(
          adminUsersActions.storeSearch({
            hits_total: total,
            sources: users
          })
        );
        return id;
      }
    }
  } catch (e) {
    dispatch(adminUsersActions.catchError({ callType: callTypesUsers.list }));
  }
};

export const assignAdminUsersCompany = (
  data,
  id,
  queryParams,
  search_keyword,
  user_status,
  company_id
) => async (dispatch) => {
  const userResponse = await requestFromServer.updateUsersCompanyList(id, data);
  const { status: userStatus } = userResponse.data;
  if (userStatus) {
    const response = await requestFromServer.getUsersList(
      queryParams,
      search_keyword,
      user_status,
      "",
      company_id
    );
    const {
      data: { users, total }
    } = response.data;

    dispatch(
      adminUsersActions.storeSearch({
        hits_total: total,
        sources: users
      })
    );
  }
};

export const updateAdminUsers = (
  queryParams,
  search_keyword,
  data,
  assignUserDetails,
  id,
  userCompanyId,
  status,
  saveExitState
) => async (dispatch) => {
  dispatch(adminUsersActions.startCall({ callType: callTypesUsers.list }));
  try {
    const response = await requestFromServer.updateUserList(id, data);
    const { status: updateStatus } = response.data;

    if (updateStatus) {
      const assignUser = Object.assign(assignUserDetails, {
        user_id: id.toString()
      });
      const userResponse = await requestFromServer.updateUsersCompanyList(
        userCompanyId,
        assignUser
      );
      const { status: userStatus } = userResponse.data;

      if (userStatus && !saveExitState) {
        const response = await requestFromServer.getUsersList(
          queryParams,
          search_keyword,
          status
        );
        const {
          data: { users, total }
        } = response.data;

        dispatch(
          adminUsersActions.storeSearch({
            hits_total: total,
            sources: users
          })
        );
      }
      return updateStatus;
    }
  } catch (e) {
    dispatch(adminUsersActions.catchError({ callType: callTypesUsers.list }));
  }
};

export const removeAdminUsers = (
  queryParams,
  id,
  search_keyword,
  searchStatus,
  referer_id
) => async (dispatch) => {
  dispatch(adminUsersActions.startCall({ callType: callTypesUsers.list }));
  try {
    const response = await requestFromServer.removeUsersList(id);
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getUsersList(queryParams, search_keyword, searchStatus, referer_id)
          .then((response_1) => {
            const {
              data: { users, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                adminUsersActions.storeSearch({
                  hits_total: total,
                  sources: users
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              adminUsersActions.catchError({
                callType: callTypesUsers.list
              })
            );
          });
      }, 500);
    } else {
      dispatch(adminUsersActions.catchError({ callType: callTypesUsers.list }));
    }
  } catch (error_1) {
    dispatch(adminUsersActions.catchError({ callType: callTypesUsers.list }));
  }
};
