export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;
export const CompanyStatusTitles = ["Disabled", "Enabled"];
export const CompanyStatusCssClasses = ["danger", "success"];

export const slugify = (str) => {
  return str
    .toString()
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, "_") //convert all special characters in string to underscore
    .replace(/[^\w-]+/g, "_") // convert any on-alphanumeric character to a underscore
    .replace(/_+/g, "_") // convert consecutive underscore to singuar one
    .replace(/^_+/g, "") // remove one or more underscore at the start of the string
    .replace(/_+$/g, ""); //remove one or more underscore at the end of the string
};
