import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const LOGOUT_URL = `${process.env.REACT_APP_API_URL}/auth/logout`;
export const UPDATE_URL = `${process.env.REACT_APP_API_URL}/me/update`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/me/info`;
export const PROFILE_MENU_URL = `${process.env.REACT_APP_API_URL}/profile`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function logout(authToken) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`
  };
  return axios.post(LOGOUT_URL, {}, { headers });
}

export function updateUser(type, name) {
  return axios.put(UPDATE_URL, { [type]: name });
}

export function getUserByToken() {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.get(ME_URL, { headers, data: {} });
}

export function getProfileMenu(user_id) {
  const headers = {
    params: {
      user_id
    },
    data: {}
  };
  return axios.get(PROFILE_MENU_URL, headers);
}
