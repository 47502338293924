/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../template/helpers";
import { ContentRoute } from "../../../../template/layout";
import Login from "./Login";
import "../../../../template/assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  const subProject = (process.env.REACT_APP_SUBPROJECT) ? '/sub-' + process.env.REACT_APP_SUBPROJECT : ''
  const projectUrl = 'project-' + process.env.REACT_APP_PROJECT + subProject

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div
          className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'
          id='kt_login'
        >
          <div
            className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/" + projectUrl + "/bg/login-background.jpg")})`
            }}
          ></div>

          <div className='d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden'>
            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
              <Switch>
                <ContentRoute path='/auth/login' component={Login} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
