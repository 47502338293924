import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./general/redux";
import store, { persistor } from "./general/redux/store";
import App from "./general/app/App";
import "./index.scss";
import "./general/template/assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "font-awesome/css/font-awesome.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./general/template/assets/plugins/flaticon/flaticon.css";
import "./general/template/assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./general/template/layout";
import { MetronicI18nProvider } from "./general/template/i18n";
import "bootstrap-icons/font/bootstrap-icons.css";

const { PUBLIC_URL } = process.env;
_redux.setupAxios(axios, store);

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
