import * as requestFromServer from "./adminMenuItemsCrud";
import { callTypesMenuItems, adminMenuItemsSlice } from "./adminMenuItemsSlice";

const { actions: adminMenusItemsActions } = adminMenuItemsSlice;

export const fetchAllMenuIcons = () => async () => {
  const response = await requestFromServer.getMenuItemIcons();
  const {
    data: { menu_item_icons }
  } = response.data;
  return menu_item_icons;
};

export const fetchAdminMenusItems = (queryParams, menu_id = 0) => async (
  dispatch
) => {
  dispatch(
    adminMenusItemsActions.startCall({ callType: callTypesMenuItems.list })
  );
  try {
    const response = await requestFromServer.getMenuItemsList(
      queryParams,
      menu_id
    );
    const {
      data: { menu_items, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        adminMenusItemsActions.storeSearch({
          hits_total: total,
          sources: menu_items
        })
      );
    }, 500);
  } catch (e) {
    dispatch(
      adminMenusItemsActions.catchError({ callType: callTypesMenuItems.list })
    );
  }
};

export const addAdminMenusItems = (data, menuId) => async (dispatch) => {
  dispatch(
    adminMenusItemsActions.startCall({ callType: callTypesMenuItems.list })
  );
  try {
    const response = await requestFromServer.addMenuItemList(data);
    const {
      status,
      data: { id }
    } = response.data;

    if (status) {
      const response = await requestFromServer.getMenuItemsList({}, menuId);
      const {
        data: { menu_items, total }
      } = response.data;

      dispatch(
        adminMenusItemsActions.storeSearch({
          hits_total: total,
          sources: menu_items
        })
      );
      return id;
    }
  } catch (e) {
    dispatch(
      adminMenusItemsActions.catchError({ callType: callTypesMenuItems.list })
    );
  }
};

export const updateAdminMenusItems = (
  queryParams,
  data,
  menuItemId,
  menuId
) => async (dispatch) => {
  dispatch(
    adminMenusItemsActions.startCall({ callType: callTypesMenuItems.list })
  );
  try {
    const response = await requestFromServer.updateMenuItemList(
      data,
      menuItemId
    );
    const { status: resStatus } = response.data;

    if (resStatus) {
      const response = await requestFromServer.getMenuItemsList(
        queryParams,
        menuId
      );
      const {
        data: { menu_items, total }
      } = response.data;

      dispatch(
        adminMenusItemsActions.storeSearch({
          hits_total: total,
          sources: menu_items
        })
      );
    }
    return resStatus;
  } catch (e) {
    dispatch(
      adminMenusItemsActions.catchError({ callType: callTypesMenuItems.list })
    );
  }
};

export const removeAdminMenusItems = (queryParams, id, menuId) => async (
  dispatch
) => {
  dispatch(
    adminMenusItemsActions.startCall({ callType: callTypesMenuItems.list })
  );
  try {
    const response = await requestFromServer.removeMenuItemList(id);
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getMenuItemsList(queryParams, menuId)
          .then((response_1) => {
            const {
              data: { menu_items, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                adminMenusItemsActions.storeSearch({
                  hits_total: total,
                  sources: menu_items
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              adminMenusItemsActions.catchError({
                callType: callTypesMenuItems.list
              })
            );
          });
      }, 500);
    } else {
      dispatch(
        adminMenusItemsActions.catchError({ callType: callTypesMenuItems.list })
      );
    }
  } catch (error_1) {
    dispatch(
      adminMenusItemsActions.catchError({ callType: callTypesMenuItems.list })
    );
  }
};

export const updateMenusItemName = (details, queryParams, menuId) => async (
  dispatch
) => {
  dispatch(
    adminMenusItemsActions.startCall({ callType: callTypesMenuItems.list })
  );
  try {
    const response = await requestFromServer.updateMenusItemName(details);
    const { data, status } = response.data;
    if (status) {
      const response = await requestFromServer.getMenuItemsList(
        queryParams,
        menuId
      );
      const {
        data: { menu_items, total }
      } = response.data;

      dispatch(
        adminMenusItemsActions.storeSearch({
          hits_total: total,
          sources: menu_items
        })
      );
      return data;
    }
  } catch (error) {
    dispatch(
      adminMenusItemsActions.catchError({ callType: callTypesMenuItems.list })
    );
  }
};
