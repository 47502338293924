import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../template/layout";
import { AuthPage } from "./modules/Auth";
import { ErrorPage } from "./pages/Error/ErrorPage";
import ListContext from "../../general/template/partials/SearchBox/ListContext";
import DynamicComponent from "../DynamicComponent";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  const BasePage = DynamicComponent(() => import(`./../../project-${process.env.REACT_APP_PROJECT}/app/BasePage`));

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorPage} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <Layout>
          <ListContext>
            <BasePage />
          </ListContext>
        </Layout>
      )}
    </Switch>
  );
}
