export const staticPageTypes = {
  azure_power_bi: "azure_power_bi",
  content: "content"
};

export const menuIcon = {
  icon_dashboard_ref: "Dashboard.svg",
  icon_search_ref: "Search.svg",
  icon_history_ref: "History.svg",
  icon_profile_ref: "Add-user.svg",
  icon_powerbi_ref: "Dashboard.svg",
  icon_search_favorites_ref: "Favorite.svg",
  icon_company_ref: "Companies.svg",
  icon_user_ref: "Users.svg",
  icon_page_ref: "Pages.svg",
  icon_menu_ref: "Menu.svg"
};

export const menuPage = {
  company_item_page: "admin_company",
  user_item_page: "admin_user",
  pages_item_page: "admin_pages",
  menus_item_page: "admin_menus"
};

export const pageRef = {
  page_dashboard_ref: "page_dashboard_ekz_ref",
  page_search_ref: "page_search_ekz_ref",
  page_history_ref: "page_history_ekz_ref",
  dashboard: "dashboard",
  page_admin_ref: "page_admin_dashboard",
  page_favorites_ref: "page_search_favorites_ref",
  page_admin_company: "page_admin_company",
  page_admin_user: "page_admin_user",
  page_admin_page: "page_admin_pages",
  page_admin_menu: "page_admin_menus"
};