import axios from "axios";
export const API_URL = `${process.env.REACT_APP_API_URL}/company`;
export const API_URL_ROLES = `${process.env.REACT_APP_API_URL}/roles`;

export function getCompanyList(
  queryParams = {},
  search_keyword = "",
  search_status = ""
) {
  const {
    pageNumber = 1,
    pageSize = 10,
    sortField = "created_at",
    sortOrder = "desc"
  } = queryParams;
  let config = {
    method: "get",
    url: `${API_URL}?from=${pageNumber * pageSize - pageSize}&size=${pageSize}${
      search_keyword !== "" ? `&filter[company_name]=${search_keyword}` : ""
    }${
      search_status !== "" ? `&filter[company_status]=${search_status}` : ""
    }&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}

export function addCompanyList(compData) {
  return axios.post(API_URL, compData);
}

export function updateCompanyList(compData, id) {
  return axios.put(`${API_URL}/${id}`, compData);
}

export function removeCompanyList(id = 0, includingUsers = false) {
  const headers = {
    "Content-Type": "application/json"
  };

  const dataObj = { including_users: includingUsers ? "1" : "0" };
  return axios.delete(`${API_URL}/${id}`, { headers, data: dataObj });
}

export function getRoles() {
  const headers = {
    data: {}
  };
  return axios.get(API_URL_ROLES, headers);
}
