import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { login } from "../_redux/authCrud";

const {actions} = require(`../../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

const initialValues = {
  email: "",
  password: ""
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH.LOGIN.FORMAT"
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MIN_LENGTH"
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH"
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then((res) => {
            const {
              status,
              data: { access_token }
            } = res.data;
            disableLoading();
            if (status) {
              props.login(access_token);
            } else {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN"
                })
              );
            }
          })
          .catch(() => {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN"
              })
            );
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    }
  });

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.LOGIN.DESC' />
        </p>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        {formik.status ? (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          ""
        )}

        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder={intl.formatMessage({
              id: "PLACEHOLDER.EMAIL"
            })}
            type='email'
            autoComplete='off'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name='email'
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder={intl.formatMessage({
              id: "PLACEHOLDER.PASS"
            })}
            type='password'
            autoComplete='off'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name='password'
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>
              <FormattedMessage id='AUTH.LOGIN.BUTTON' />
            </span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, actions)(Login));
