import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, getProfileMenu, logout } from "../../../../../general/app/modules/Auth/_redux/authCrud";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import {
  adminCompaniesSlice,
  adminMenuItemsSlice,
  adminMenusSlice,
  adminPagesSlice,
  adminUsersSlice
} from "../../../../../general/app/modules/AdminList";
import {dynamicSlice} from "../../../../../general/app/modules/DynamicSections";

const { actions: adminCompaniesActions } = adminCompaniesSlice;
const { actions: adminUsersActions } = adminUsersSlice;
const { actions: adminPagesActions } = adminPagesSlice;
const { actions: adminMenusActions } = adminMenusSlice;
const { actions: adminMenusItemsActions } = adminMenuItemsSlice;
const { actions: dynamicSliceActions } = dynamicSlice;

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  profile: { menu: {}, pages: [] }
};

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2
};

export const reducer = persistReducer(
  persistConfig,

  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { ...state, authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user, profile } = action.payload;
        return { ...state, user, profile };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  logout: (authToken) => ({ type: actionTypes.Logout, authToken }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user }
  }),
  fulfillUser: (user, profile) => ({
    type: actionTypes.UserLoaded,
    payload: { user, profile }
  }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const res = yield getUserByToken();
    const {
      data: { user_data }
    } = res.data;
    const profile_res = yield getProfileMenu(user_data[0].user_id);
    const {
      data: { profile }
    } = profile_res.data;

    yield put(actions.fulfillUser(user_data[0], profile));
  });

  yield takeLatest(actionTypes.Logout, function* logoutSaga(action) {
    yield put(adminCompaniesActions.logoutUser());
    yield put(adminUsersActions.logoutUser());
    yield put(adminPagesActions.logoutUser());
    yield put(adminMenusActions.logoutUser());
    yield put(adminMenusItemsActions.logoutUser());
    yield put(dynamicSliceActions.logoutUser());
    yield logout(action.authToken);
  });
}
