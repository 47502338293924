import React, { Component } from "react";
import store from "../../../redux/store";

const {actions} = require(`../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

export class ErrorBoundry extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      store.dispatch(actions.logout());
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundry;
