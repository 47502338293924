import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../helpers";
import { menuIcon } from "../../../../partials/reference/DynamicMenuRef";
import _ from "lodash";
import { useLocale } from "../../../../i18n";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const selectedLocale = useLocale();
  const {
    profile: { menu }
  } = useSelector((state) => state.auth, shallowEqual);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menu.hasOwnProperty("menu_items")
          ? menu.menu_items.map((value) => (
              <li
                key={value.id}
                className={`menu-item ${getMenuItemActive(
                  `/${value.menu_item_reference}`,
                  false
                )}`}
                aria-haspopup='true'
              >
                <NavLink
                  className='menu-link'
                  to={`/${value.menu_item_reference}`}
                >
                  <span className='svg-icon menu-icon'>
                    <SVG
                      src={toAbsoluteUrl(
                        `/media/general/svg/icons/General/${
                          menuIcon[value.menu_item_icon]
                        }`
                      )}
                    />
                  </span>
                  <span className='menu-text'>
                    {_.find(value.menu_item_name, function(o) {
                      return o.locale === selectedLocale;
                    }) !== undefined
                      ? _.find(value.menu_item_name, function(o) {
                          return o.locale === selectedLocale;
                        }).value
                      : value.menu_item_name}
                  </span>
                </NavLink>
              </li>
            ))
          : ""}
      </ul>
    </>
  );
}
