import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {reducer, saga} from "../app/modules/Auth/_redux/authRedux";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import {dynamicSlice} from "../../general/app/modules/DynamicSections";
import {
  adminCompaniesSlice,
  adminUsersSlice,
  adminPagesSlice,
  adminMenusSlice,
  adminMenuItemsSlice
} from "../../general/app/modules/AdminList";

export const rootReducer = combineReducers({
  auth: reducer,
  dynamic: persistReducer(
    {
      key: "root1",
      storage,
      stateReconciler: autoMergeLevel2
    },
    dynamicSlice.reducer
  ),
  adminCompanies: persistReducer(
    {
      key: "root4",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminCompaniesSlice.reducer
  ),
  adminUsers: persistReducer(
    {
      key: "root5",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminUsersSlice.reducer
  ),
  adminPages: persistReducer(
    {
      key: "root6",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminPagesSlice.reducer
  ),
  adminMenus: persistReducer(
    {
      key: "root7",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminMenusSlice.reducer
  ),
  adminMenuItems: persistReducer(
    {
      key: "root8",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminMenuItemsSlice.reducer
  )
});

export function* rootSaga() {
  yield all([saga()]);
}
