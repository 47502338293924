import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    typography: {
      fontFamily: ["Poppins"].join(",")
    },
    overrides: {
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "unset"
          }
        }
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottom: "none"
          },
          "&:after": {
            borderBottom: "none"
          },
          "&:hover:not($disabled):before": {
            borderBottom: "none !important"
          },
          "&:focus:not($focused):before": {
            borderBottom: "none !important"
          },
          "&:active:not($focused):before": {
            borderBottom: "none !important"
          }
        }
      },
      MuiInputBase: {
        input: {
          padding: 0
        }
      },
      MuiSvgIcon: {
        root: {
          display: "none"
        }
      },
      MuiSwitch: {
        colorPrimary: {
          "&$checked": {
            color: "#3699ff"
          }
        },
        track: {
          "$checked$checked + &": {
            backgroundColor: "#3699ff"
          }
        }
      }
    },
    palette: {
      primary: {
        main: "#17c191"
      },
      secondary: {
        main: "#3783e7"
      },
      error: {
        main: "#f018a6"
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      MuiButtonBase: {
        disableRipple: false // No more ripple, on the whole application 💣!
      },
      MuiPopover: {
        elevation: 1
      }
    }
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
